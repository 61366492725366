/* stylelint-disable property-no-vendor-prefix */
import {forwardRef, useCallback, useMemo, useState} from 'react';

import styled from 'styled-components';
import {isEmpty} from 'lodash';

import clearTextIconUrl from '~/assets/images/icons/clear_text.svg';
import {flexBottomVertically} from '~/shared/theme/FlexLayout';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
 
import {flipOnLTR} from '../../../theme/utils';

import FormInputActionButton from './FormInputActionButton';
import Label from './FormInputLabel';

// #region styled components
const RootBase = styled.div`
  position: relative;
  width: 100%;
  ${flipOnLTR`
    padding-right: 8px;
    text-align: right;
  `};
  background-color: ${({theme}) => theme.colors.surface};
  ${flexBottomVertically};
  ${({withBorder, theme}) => withBorder && `
    border: 1px solid ${theme.colors.gray500};
  `}
  ${({isFocused, theme}) => isFocused && `
    border: solid 1px ${theme.colors.text};
  `}
  ${({hasError, theme}) => hasError && `
    border: solid 1px ${theme.colors.error};
  `}
`;

const BasicInputRoot = styled(RootBase)`
  height: 48px;
  padding-bottom: 5px;
`;

const BasicInput = styled.input`
  width: 100%;
  height: 20px;
  border: none;
  outline: none;
  padding: 0;

  &&& {
    color: ${({theme}) => theme.colors.secondary};
    font-size: 16px;
    line-height: 20px;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.5;
  }

  ${({type}) =>
    type === 'number' &&
    `
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    -moz-appearance: textfield;
  `};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.colors.surface} inset !important;
    -webkit-text-fill-color: ${({theme}) => theme.colors.secondary} !important;

    ~ div {
      top: 6px;
      font-size: 9px;
    }
  }
`;

const ClearButton = styled(ImageWithAlt).attrs({
  src: clearTextIconUrl,
})`
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  ${flipOnLTR`
    left: 10px;
  `};
  width: 15px;
`;

const TextAreaRoot = styled(RootBase)`
  height: 100%;
  min-height: initial;
  padding: 22px 0 0 0;
`;

const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  border: 0;
  outline: none;
  ${({allowResize}) => allowResize || 'resize: none'};
  color: ${({theme}) => theme.colors.secondary};
  font-size: 13px;
  padding: 0 10px !important;
`;
// #endregion

const FormInput = forwardRef(
  (
    {
      type = 'text',
      selectOnFocus,
      onFocus,
      onBlur,
      value,
      hasError,
      className,
      required,
      id,
      disabled,
      onClearButtonClick,
      placeholder,
      active,
      actionButton,
      withBorder,
      ...nativeInputProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isTextArea = useMemo(() => type === 'textarea', [type]);

    const handleFocus = useCallback(() => {
      setIsFocused(true);
      onFocus?.();
      if (selectOnFocus) {
        ref?.select();
      }
    }, [onFocus, selectOnFocus, ref]);

    const handleBlur = useCallback(() => {
      setIsFocused(false);
      onBlur?.();
    }, [onBlur]);

    const Root = isTextArea ? TextAreaRoot : BasicInputRoot;
    const Component = isTextArea ? TextArea : BasicInput;

    const inputProps = {
      ...nativeInputProps,
      id,
      type,
      required,
      disabled,
      onFocus: handleFocus,
      onBlur: handleBlur,
      value,
    };

    return (
      <Root {...{isFocused, hasError, className}} withBorder={withBorder}>
        <Component ref={ref} {...inputProps} />
        <Label
          htmlFor={id}
          error={hasError}
          disabled={disabled}
          float={!!value || isFocused}
          required={required}
          text={placeholder}
        />
        {isTextArea && onClearButtonClick && <ClearButton onClick={onClearButtonClick} />}
        {!active && !isEmpty(actionButton) && <FormInputActionButton actionButton={actionButton} value={value} />}
      </Root>
    );
  },
);

export default FormInput;
