import {memo, useMemo} from 'react';

import styled from 'styled-components';
import {Form, Field} from 'react-final-form';
import {FORM_ERROR} from 'final-form';

import {flipOnLTR} from '~/shared/theme/utils';
import Checkbox from '~/shared/components/Checkbox';
import {ActionButton} from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import {body12Normal, body15Bold} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';
// @ts-expect-error config needs to be rewritten as ts
import {basedir} from '~/shared/config';
import {secureOpenWindow} from '~/shared/utils/general';
import {trackEvent} from '~/shared/services/analytics';
import apiService from '~/shared/services/apiService';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';
import {MoneycardOtlRuleManagement, OtlRuleDataLimitationName} from '~/shared/store/models';

import OtlRuleLine from './OtlRuleLine';

const Root = styled.div`
  ${flexColumn};
  ${flipOnLTR`
    text-align: right;
  `}
`;

const Title = styled.div`
  ${body15Bold};
  color: ${({theme}) => theme.checkout.otl.textColor};
  line-height: 22px;
  margin-bottom: 10px;
`;

const Text = styled.div<{nowrap?: boolean}>`
  color: ${({theme}) => theme.checkout.otl.textColor};
  ${body12Normal};
  line-height: 16px;
  ${({nowrap}) => nowrap && 'white-space: nowrap'};
`;

const Rules = styled.div`
  margin-top: 18px;
  margin-bottom: 50px;
`;

const Rule = styled.div`
  margin-bottom: 10px;
`;

const ApproveTOSWrapper = styled.div`
  display: flex;
`;

const TOSLink = styled.div`
  color: ${({theme}) => theme.checkout.otl.textColor};
  white-space: nowrap;
  ${body12Normal};
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 2px 0 2px;
`;

const FieldError = styled.div`
  color: ${({theme}) => theme.checkout.errors.errorText};
`;

const SubmitButton = styled(ActionButton)`
  margin: 15px 0 20px 0;
`;

const TermsOfServiceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type OtlRuleLimitationData = {
  isActive: boolean;
  value: string;
};

type FormValues = Record<OtlRuleDataLimitationName | 'termsOfServiceApproved', OtlRuleLimitationData>;

const validate = ({SingleTransaction, Daily, Monthly, termsOfServiceApproved}: FormValues) => {
  const err: Record<typeof FORM_ERROR, string | undefined> = {
    [FORM_ERROR]: undefined,
  };

  if (!SingleTransaction.isActive && !Daily.isActive && !Monthly.isActive) {
    err[FORM_ERROR] = 'you_must_define_at_least_one_rule';
  }
  if (SingleTransaction.isActive && Daily.isActive && SingleTransaction.value > Daily.value) {
    err[FORM_ERROR] = 'the_daily_limit_must_be_bigger_or_equal_to_the_single_transaction_limit';
  }
  if (Daily.isActive && Monthly.isActive && Daily.value > Monthly.value) {
    err[FORM_ERROR] = 'the_monthly_limit_must_be_bigger_or_equal_to_the_daily_limit';
  }
  if (SingleTransaction.isActive && Monthly.isActive && SingleTransaction.value > Monthly.value) {
    err[FORM_ERROR] = 'the_monthly_limit_must_be_bigger_or_equal_to_the_single_transaction_limit';
  }

  if (!termsOfServiceApproved) {
    err[FORM_ERROR] = 'you_must_approve_the_terms_of_service';
  }
  return err;
};

const termsOfServiceOnClick = () => {
  secureOpenWindow(`${basedir}over-the-limit-terms`, '_blank');
};

const RuleNames = {
  SingleTransaction: 'single_transaction_maximum',
  Daily: 'daily_maximum',
  Monthly: 'monthly_maximum',
};

type OtlRuleLimitationsProps = {
  otlRuleData?: MoneycardOtlRuleManagement['otlRuleData'];
  selectedCreditCard: number | null;
  onSuccess?: () => void;
};

const OtlRuleLimitations = ({otlRuleData, selectedCreditCard, onSuccess}: OtlRuleLimitationsProps) => {
  const {t} = getLocalizationService();

  const {limitation} = otlRuleData || {};

  const rules = useMemo(
    () =>
      limitation?.map(({limitationName}) => ({
        limitationName,
        ruleName: t(RuleNames[limitationName]),
        inputType: 'number',
        parse: ({value, isActive}: {value: string; isActive: boolean}) => ({value: Math.max(parseInt(value, 10), 0), isActive}),
      })) || [],
    [limitation, t],
  );

  const initialValues = useMemo(
    () =>
      limitation?.reduce(
        (acc, {limitationName, value, isActive}) => ({
          ...acc,
          [limitationName]: {value, isActive},
        }),
        {},
      ) || {},
    [limitation],
  );

  const onSubmit = async (formValues: FormValues) => {
    trackEvent('hasApprovedCreditCard');

    const handleSubmit = async () => {
      await apiService.UpsertOverTheLimitRule({
        ...otlRuleData,
        ruleActive: true,
        creditcardId: selectedCreditCard,
        limitation: Object.entries(formValues).map(([limitationName, {value, isActive}]) => ({
          limitationName,
          value,
          isActive,
        })),
      });

      // if there was no error, initiating onSuccess callback
      if (onSuccess) {
        onSuccess();
      }
    };

    try {
      await handleSubmit();
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, handleSubmit);
        return;
      }
      return {[FORM_ERROR]: error instanceof Error ? error.message : 'Unexpected error occurred'};
    }
  };

  return (
    <Root>
      <Title>{t('choose_the_charge_limitations_for_this_credit_card')}</Title>
      <Text>
        {t(
          'you_can_define_a_limit_that_can_not_be_exceeded_on_order_time_in_any_of_the_following_ways_you_can_come_back_and_change_the_settings_at_any_time_in_your_personal_zone',
        )}
      </Text>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({handleSubmit, errors, submitFailed}) => (
          <>
            <Rules>
              {rules.map(({limitationName, parse, ...rest}) => (
                <Rule key={limitationName}>
                  <Field
                    name={limitationName}
                    parse={parse}
                    render={({input, meta}) => (
                      <>
                        <OtlRuleLine {...rest} {...input} />
                        <FieldError>{meta.error && t(meta.error)}</FieldError>
                      </>
                    )}
                  />
                </Rule>
              ))}
            </Rules>
            <ApproveTOSWrapper>
              <Field
                name="termsOfServiceApproved"
                render={({input: {value, onChange}, meta}) => (
                  <>
                    <Checkbox
                      value={value}
                      onValueChange={onChange}
                      name="termsOfServiceApproved"
                      id="termsOfServiceApproved_form_checkbox"
                      outlined
                    />
                    <FieldError>{meta.error && t(meta.error)}</FieldError>
                  </>
                )}
              />
              <TermsOfServiceWrapper>
                <Text>{t('i_read_the')}</Text>
                <TOSLink onClick={termsOfServiceOnClick}>{t('terms_of_service')}</TOSLink>
                <Text>{t('and_i_agree_to_the_ten_bis_terms_of_use_without_reservations')}</Text>
              </TermsOfServiceWrapper>
            </ApproveTOSWrapper>
            <FieldError>{submitFailed && errors && errors[FORM_ERROR] && t(errors[FORM_ERROR])}</FieldError>
            <SubmitButton onClick={handleSubmit} full>
              <>{t('approve')}</>
            </SubmitButton>
          </>
        )}
      />
    </Root>
  );
};

export default memo(OtlRuleLimitations);
