import {memo, useMemo} from 'react';

import styled from 'styled-components';

import {Padder} from '~/shared/components/styled';
import {media} from '~/shared/theme/utils';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {flexCenterHorizontally, flexColumn} from '~/shared/theme/FlexLayout';
import {MoneycardOtlRuleManagement} from '~/shared/store/models';

import OtlRuleLimitations from './OtlRuleLimitations';
import OtlCreditCardList from './OtlCreditCardList';
import {ModifiedCreditCard} from './types';

const Container = styled(Padder)`
  ${flexCenterHorizontally}
`;

const Root = styled.div`
  ${flexColumn};
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  ${media.minMobile`
    margin-top: 0;
  `}
  ${media.minLargeTablet`
    width: 70%;
  `}
  ${media.minDesktop`
    width: 550px;
  `}
`;

type OtlRuleSettingsProps = {
  addCreditCard: () => void;
  selectedCreditCard: number | null;
  setSelectedCreditCard: (selectedCreditCard: number | null) => void;
  onClose?: () => void;
  otlData: MoneycardOtlRuleManagement | null;
  onSuccess?: () => void;
  withoutHeader?: boolean;
  withoutCreditCardsList?: boolean;
};

const OtlRuleSettings = ({
  addCreditCard,
  selectedCreditCard,
  setSelectedCreditCard,
  onClose,
  otlData,
  onSuccess,
  withoutCreditCardsList,
  withoutHeader,
}: OtlRuleSettingsProps) => {
  const {otlRuleData, userCreditcardList} = otlData || {};

  const creditCardList = useMemo(
    () =>
      userCreditcardList?.map<ModifiedCreditCard>(({creditcardId, ownerName, creditcardSuffix, creditCardType}) => ({
        creditcardId,
        creditCardType,
        userName: ownerName,
        cardLastDigits: creditcardSuffix,
        paymentMethod: PaymentMethodTypes.CREDIT_CARD.toLowerCase(),
      })) || [],
    [userCreditcardList],
  );

  const content = (
    <Container>
      <Root>
        {!withoutCreditCardsList && <OtlCreditCardList {...{addCreditCard, creditCardList, selectedCreditCard, setSelectedCreditCard}} />}
        <OtlRuleLimitations {...{otlRuleData, selectedCreditCard, onSuccess}} />
      </Root>
    </Container>
  );

  if (withoutHeader) {
    return content;
  }

  return (
    <LayoutWithHeader onClick={onClose} titleKey="otl_assign_credit_card" showBackArrow>
      {content}
    </LayoutWithHeader>
  );
};
export default memo(OtlRuleSettings);
