import {RestaurantFromSearch, User} from '~/shared/store/models';
import {DeliveryMethod} from '~/shared/consts/restaurantConsts';
import {OrderState} from '~/shared/store/storeModules/order/orderReducer';

// use this without combination with what value the user has selected
export const filterTypesMapForAnalyticsNoPrivacy = {
  isVegan: 'vegan',
  isGlutenFree: 'glutenFree',
  isKosher: 'isKosher',
  isNotKosher: 'isNotKosher',
  discountCoupon: 'discount',
  newRestaurants: 'new',
  freeDelivery: 'deliveryCost',
  isEnvironmentFriendly: 'filterEcoFriendly',
  isScoober: 'freeDeliveryWithTracking',
};

export type FilterTypeKey = keyof typeof filterTypesMapForAnalyticsNoPrivacy;

export type AnalyticsDataProps = {
  userData: {
    companyId: string;
    crossPlatformCustomerId: string;
  } | null;
  userType: string | null;
  currentAddressCityName: string | null;
};

export interface CurrentProduct {
  productID: number;
  productName: string;
  // starting from 1, the position in productList and not on the whole page
  productPosition?: number;
  // these are actual lists; for now we can use what we have,
  // if in the future we update it we can update it here as well
  // indicates the group of the item: open/closed/pooled order
  productList?: string | number;
  productCategory?: string;
  productPrice?: number;
  productVariant?: 'ageRestricted';
  productQuantity?: number;
}

export enum GoogleAnalyticsEvents {
  PAGE_VIEW = 'page_view',
  MENU_VIEW = 'menu_view',
  PURCHASE = 'purchase',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  // TODO: - waiting for product to implement view_cart
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
}

export type GoogleAnalyticsCurrentProduct = {
  item_id: number | string;
  item_name: string;
  item_variant?: string;
  item_category?: string;
  price?: number;
  quantity?: number;
};

export type TrackTealiumEventProps = {
  name: string;
  key?: string;
  eventData?: Record<string, any>;
  pageData?: Record<string, any>;
  restaurantData?: Record<string, any>;
  currentProducts?: CurrentProduct[];
  gaModifiedData?: ({
    ecommerce?: {
      items: GoogleAnalyticsCurrentProduct[];
    };
  } & Record<string, any>) | null;
};

type RestaurantData = {
  restaurantID: string;
  restaurantName?: string;
  restaurantCity?: string;
  restaurantDeliveryTime?: number;
  restaurantRating?: number;
  restaurantMinimumPriceForOrder?: number;
  restaurantNumOfReviews?: number;
  restaurantFreeDelivery?: boolean;
  deliveryMethod?: DeliveryMethod;
  restaurantOpeningStatus?: string;
  isScooberRestaurant?: boolean;
  isRestaurantPreOrder?: number;
  cuisineId?: string;
  orderTypeResOptions?: string;
};

type TransactionData = {
  isFirstTimeOrder?: boolean;
  transactionRevenueWithTax: number;
  transactionDiscount: number;
  transactionID: number;
  transactionType: 'B2B' | 'B2C';
  transactionCustomer: 'B2B' | 'B2C';
  transactionAffiliation: string;
  transactionRestaurant: string;
  transactionCity: string;
  transactionZipArea: string;
  transactionCouponCode: string;
  transactionTime: string;
  transactionDay: string;
  transactionRestaurantPosition: number;
  transactionPrivateAddressOrdersCount: number;
  transactionIsFirstTimePrivateOrder: boolean;
  transactionShippingCost: number;
  hasReorderedInOrderHistory: number;
  poolGroupId?: string;
  transactionOrderType: string;
};

type EventPayload = {
  restaurantData?: RestaurantData;
  transactionData?: TransactionData;
  pageData?: {
    city?: string;
    filterKitchenName: string;
  };
  userReportData?: {
    showOrder: string;
  };
  currentProducts?: CurrentProduct[];
  LoggedInType?: 'b2b' | 'b2c' | null;
  isLoggedIn?: boolean;
  url?: string;
  groupName?: string;
  restaurantGroupId?: string;
};

export type TrackPageviewProps = {
  key?: string;
  eventPayload?: EventPayload;
  currentLanguageKey: string;
  pageGroup: string;
};

// #region events
export type EventClickedSearchResultProps = {
  searchString: string;
  countResultsShown: boolean;
  selectedItemType: string;
  selectedItemName: string;
  selectedItemID: number;
  selectedItemPositionResultsList: number;
  selectedItemPositionTypeList: number;
  searchListValue: string;
};

export type EventLoginProps = {
  userData: User;
  isAutomaticLogin: boolean;
  currentAddressCityName: string;
};

export type EventOrderSuccessProps = {
  addressCompanyId: string | number;
  orderSuccessData: OrderState['successData']['data'];
  userData: User;
  isFirstTimePrivateOrder: boolean;
  orderTotal: number | string;
};

export type EventSelectResProps = {
  restaurantData: RestaurantFromSearch;
  secondOrderedByConfigUsed: boolean;
  groupId: number | string;
  itemIndexInGroup: number;
};

export type EventDeleteAccountProps = {
  identificationProcess: 0 | 1;
};

export enum EnumOfficeSupplyOptions {
  EQUIPMENT_SERVICES = 'equipment services',
  EVENTS_REFRESHMENTS = 'events refreshments',
  KITCHEN_SUPPLIES = 'kitchen supplies',
}

export type EventHasClickedOfficeSupplyFilterProps = {
  officeSupplyOption: EnumValueType<typeof EnumOfficeSupplyOptions>;
};

export enum ActiveOrderOnClickLinkTypes {
  CLOSE = 'close',
  OPEN = 'open',
}

export type ActiveOrderOnClickLinkType = EnumValueType<typeof ActiveOrderOnClickLinkTypes>;

export enum ActiveOrderOrderTypes {
  ASAP = 'asap',
  FUTURE = 'future',
  POOLED = 'pooled',
}
export type ActiveOrderOrderType = EnumValueType<typeof ActiveOrderOrderTypes>;

export type ActiveOrderOnClickProps = {
  orderType: ActiveOrderOrderType;
  restaurantID: string;
  transactionID: string;
  linkType: ActiveOrderOnClickLinkType;
  orderWidgetStatus: string;
};

export type ActiveOrderRecivedProps = {
  orderType: ActiveOrderOrderType;
  restaurantID: string;
  transactionID: string;
};

export enum FormNamesEnum {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  OTP_LOGIN = 'otp_login',
  OTP_REGISTRATION = 'otp_registration',
  PASSWORD_LOGIN = 'password_login',
}
type FormName = EnumValueType<typeof FormNamesEnum>;

export type FormErrorMessageProps = {
  formName: FormName;
  errorType: string;
};

export type ViewedCarouselProps = {
  carouselName: string;
  carouselPosition: number;
  resCrPosition: number;
  resCrId: number;
};

export type HasClickedShowAllCarouselProps = {
  carouselName: string;
  carouselPosition: number;
};

export type EventDataType = Record<string, string | number | boolean>;

export type TenBisCreditEventData = {
  availableBalance: number;
  transferBalance: number;
  errorType?: string;
};

export enum TenBisCreditEventTypes {
  TRY_AGAIN = 'tryAgain',
  EDIT = 'edit',
  CONTINUE = 'continue',
  SUCCESS = 'success',
  FAILED = 'failed',
  CONFIRM = 'confirm',
  CLICK = 'click',
  READ_MORE = 'readMore'
}

export type NlxOrderTypeSelectProps = {
  selected_orderType: string;
  original_orderType: string;
  pageSource: string;
  futureOrderTime: string;
  delivery_price: string;
};

export enum GeneralErrorModalEventNames {
  FAR_RES_VIEW = 'reachedFarRes_view',
  FAR_RES_CLICK = 'reachedFarRes_click',
  CLOSED_RES_VIEW = 'closedRes_view',
  CLOSED_RES_CLICK = 'closedRes_click',
}

export type GeneralErrorModalClickOptions = 'change_address' | 'restaurants_in_my_area' | 'back_to_menu' | 'to_active_restaurants' | 'X';

// #endregion
declare global {
  interface Window {
    tmsController: {
      data: Record<string, any>;
      trackEvent: (name: string) => void;
      trackPageview: () => void;
    };
    tmsLoaded: Promise<void>;
    dataLayer: unknown[];
  }
}
