import {getMoneyCard} from '~/shared/utils/payments';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {Payment} from '~/shared/store/models';

import {MoneycardAndCreditCard} from './types';

export const getMoneyCardAndCreditCardFromPayments = (paymentsProcessedData: MoneycardAndCreditCard, payment: Payment): MoneycardAndCreditCard => {
  if (paymentsProcessedData[PaymentMethodTypes.CREDIT_CARD] && paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return paymentsProcessedData;
  }

  if (getMoneyCard(payment) && !paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return {
      ...paymentsProcessedData,
      [PaymentMethodTypes.MONEY_CARD]: payment,
    };
  }

  return {
    ...paymentsProcessedData,
    [PaymentMethodTypes.CREDIT_CARD]: payment.paymentMethod === PaymentMethodTypes.CREDIT_CARD ? payment : null,
  };
};
