import {useState, ReactNode, LabelHTMLAttributes} from 'react';

import styled, {css} from 'styled-components';
import {noop} from 'lodash';

import {flipOnLTR} from '~/shared/theme/utils';
import {createLogger} from '~/shared/logging';
import {ENTER_KEY_CODE} from '~/shared/consts/commonConsts';
import CheckboxIconComponent from '~/assets/images/icons/checkmark_green.svg?react';
import CheckboxIconOutlineComponent from '~/assets/images/icons/checkmark_outline.svg?react';

import {body14Normal} from '../theme/typography';
import {flexCenter} from '../theme/FlexLayout';

const logger = createLogger('Checkbox');

const CheckboxWrapper = styled.div<{isDisabled?: boolean}>`
  cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
  display: flex;
  flex: 1;
`;

const CheckboxLabel = styled.label<{
  alignItems?: string;
  isDisabled?: boolean;
  disablePointerEvents?: boolean;
  labelFirst?: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  align-items: ${({alignItems}) => alignItems || 'center'};
  user-select: none;
  line-height: ${({theme}) => theme.checkbox.height};
  ${body14Normal};
  cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
  ${flipOnLTR`
    margin-left: 5px;
    text-align: right;
  `};
  ${({disablePointerEvents}) => disablePointerEvents && 'pointer-events: none;'}
  ${({labelFirst}) =>
    labelFirst &&
    `
      margin-bottom: 10px;
      white-space: nowrap
  `}
  &:last-child {
    margin-bottom: 0;
    ${flipOnLTR`
      margin-left: 0;
  `};
  }
`;

const CheckboxInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))<{isDisabled?: boolean}>`
  z-index: 10;
  position: absolute;
  opacity: 0;
  top: 0;
  ${flipOnLTR`
    right: 0;
  `};
  cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
  width: ${({theme}) => theme.checkbox.width};
  height: ${({theme}) => theme.checkbox.height};
  margin: 0;
`;

const getColor = ({checkbox: {on, off}}: any, isActive: boolean) => (isActive ? on : off);

const Rect = styled.div.attrs(({focused}: {focused: boolean}) => {
  return {
    className: `white-bg-on-contrast ${focused ? 'checkbox-label-focused' : ''}`,
  };
})<{focused: boolean; checkedValue: boolean; isDisabled?: boolean; noBorder?: boolean; size?: number}>`
  ${flexCenter}
  ${({noBorder}) => !noBorder && 'border: 1px solid rgba(0, 0, 0, 0.3);'}
  width: ${({theme, size}) => (size ? `${size}px` : theme.checkbox.width)};
  height: ${({theme, size}) => (size ? `${size}px` : theme.checkbox.height)};
  border-radius: 2px;
  background-color: ${({theme, isDisabled}) => (isDisabled ? theme.colors.gray800 : theme.colors.surface)};
  color: ${({checkedValue, theme}) => getColor(theme, checkedValue).textColor};
  flex-shrink: 0;
  cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
  ${flipOnLTR`
    margin-left: 5px;
  `};
`;

const checkmarksStyle = css`
  z-index: 1;
  display: flex;
`;

const Checkmark = styled(CheckboxIconComponent).attrs({
  className: 'white-bg-on-contrast',
})`
  ${checkmarksStyle}

  path {
    fill: ${({theme}) => theme.checkbox.markColor};
  }
`;

const CheckmarkOTL = styled(CheckboxIconOutlineComponent).attrs({
  className: 'white-bg-on-contrast',
})`
  ${checkmarksStyle}
  border-radius: 5px;
`;

const LabelWrapper = styled.div`
  width: 100%;
`;

const CheckboxIconAndLabel = ({
  label,
  labelExtra,
}: {
  label: ReactNode;
  labelExtra?: string;
}) => {
  const labelElement =
    label || labelExtra ? (
      <LabelWrapper>
        {label && <span data-id="label-text">{label}</span>}
        {labelExtra && <span data-id="label-text-extra">&nbsp;{labelExtra}</span>}
      </LabelWrapper>
    ) : null;

  return (
    <>
      {labelElement}
    </>
  );
};

type CheckboxProps = {
  className?: string;
  label?: ReactNode;
  labelExtra?: string;
  labelFirst?: boolean;
  hasAltLngIcons?: boolean;
  icon?: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
  alignItems?: string;
  disablePointerEvents?: boolean;
  required?: boolean;
  backgroundColor?: string;
  isDisabled?: boolean;
  id: string;
  name: string;
  outlined?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

const Checkbox = ({
  className,
  label,
  labelExtra,
  labelFirst,
  value,
  onValueChange = noop,
  alignItems,
  disablePointerEvents,
  required,
  backgroundColor,
  isDisabled,
  id,
  name,
  outlined,
  ...props
}: CheckboxProps) => {
  const [focused, setFocused] = useState(false);

  const inputId = id || name;
  if (!inputId) {
    logger.warn('no id/name was provided to the Checkbox', {label, labelExtra, className});
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      onValueChange(e.target.checked);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY_CODE && !isDisabled) {
      onValueChange(!e.target.checked);
    }
  };

  const handleOnFocus = () => {
    if (!isDisabled) {
      setFocused(true);
    }
  };

  const handleOnBlur = () => {
    if (!isDisabled) {
      setFocused(false);
    }
  };

  const onParentKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY_CODE && !isDisabled) {
      onValueChange(!value);
    }
  };

  return (
    <CheckboxWrapper isDisabled={isDisabled}>
      <CheckboxLabel
        isDisabled={isDisabled}
        onKeyDown={onParentKeyDown}
        onFocus={handleOnFocus}
        {...{alignItems, disablePointerEvents, labelFirst, className, htmlFor: inputId, ...props}}
      >
        <Rect
          focused={focused}
          onFocus={handleOnFocus}
          isDisabled={isDisabled}
          {...{checkedValue: value, backgroundColor}}
          noBorder={outlined && !!value}
          size={outlined ? 20 : undefined}
        >
          {value && !outlined && <Checkmark onFocus={handleOnFocus} alt="checked" />}
          {value && outlined && <CheckmarkOTL onFocus={handleOnFocus} alt="checked" />}
        </Rect>
        <CheckboxIconAndLabel label={label} labelExtra={labelExtra} />
      </CheckboxLabel>
      <CheckboxInput
        {...{
          id: inputId,
          'aria-required': !!required,
          'aria-checked': !!value,
          isDisabled,
          required,
          checked: !!value,
          onChange: handleChange,
          onKeyDown: handleKeyDown,
          onFocus: handleOnFocus,
          onBlur: handleOnBlur,
        }}
      />
    </CheckboxWrapper>
  );
};

type FinalFormCheckboxAdapterProps = CheckboxProps & {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onBlur: () => void;
};

export const FinalFormCheckboxAdapter = ({onChange, value, name, onBlur, ...rest}: FinalFormCheckboxAdapterProps) => (
  <Checkbox
    value={value}
    name={name}
    {...rest}
    onValueChange={v => {
      onChange(v);
      onBlur(); // we call final form's onBlur to force validation when using validateOnBlur on the form
    }}
  />
);

export default Checkbox;
