import {useState} from 'react';

import {getLocalizationService} from '~/shared/services/localisationService';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import apiService from '~/shared/services/apiService';
import {trackEvent} from '~/shared/services/analytics';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {LoginBody, BodyWrapper} from '~/shared/components/loginStyled';
import {splitOnFirstSpace} from '~/shared/utils/general';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';
import {User} from '~/shared/store/models';
import {isApiError} from '~/shared/services/apiErrorService';
import {createLogger} from '~/shared/logging';
import {FormValues} from '~/shared/components/SignIn/types';

import FacebookSection from './FacebookSection';
import PhoneVerificationSection from './PhoneVerificationSection';
import SignUpForm from './SignUpForm';

const logger = createLogger('SignUp');

type SignUpProps = {
  openFacebook?: () => void;
  backgroundColor?: string;
  onBackButtonClick?: () => void;
  pageView?: boolean;
  openLoginScreen?: (args: {email: string}) => void;
  isFB?: boolean;
  onSuccess?: (user?: User) => void;
  withTransparentBG?: boolean;
  disableExternalProvidersRegistration?: boolean;
};

const SignUp = ({
  openFacebook,
  backgroundColor: _backgroundColor,
  onBackButtonClick,
  pageView,
  openLoginScreen,
  isFB,
  onSuccess,
  withTransparentBG,
  disableExternalProvidersRegistration = false,
}: SignUpProps) => {
  const {t} = getLocalizationService();

  const [activationModeProps, setActivationModeProps] = useState<{signUpCredentials: FormValues} | null>(null);
  const [sendActivationCodeError, setSendActivationCodeError] = useState('');
  const [registerFromValues, setRegisterFromValues] = useState<FormValues>({
    fullName: '',
    email: '',
    cellPhone: '',
    agreeToTerms: false,
    wantPromotion: true,
  });
  const [activationToken, setActivationToken] = useState<unknown>(null);
  const [activationCodeError, setActivationCodeError] = useState<string | null>(null);

  const onSendActivationCode = async (signUpCredentials: FormValues) => {
    try {
      const {data: activationCodeData} = await apiService.getActivationTokenAndSendActivationCodeToUser({
        cellPhone: signUpCredentials.cellPhone,
        email: signUpCredentials.email,
      });
      if (activationCodeData) {
        setActivationModeProps({signUpCredentials});
        setActivationToken(activationCodeData);
        setSendActivationCodeError('');
      }
    } catch (error) {
      if (isApiError(error)) {
        trackEvent('formErrorMessageView', {
          formName: FormNamesEnum.REGISTRATION,
          errorType: error?.message,
        });
        if (error?.message) {
          setSendActivationCodeError(error?.message);
        } else {
          setSendActivationCodeError(
            'the_phone_number_you_entered_already_exists_please_login_or_signup_with_different_number',
          );
        }

        return;
      }

      logger.error('Unexpected Error');
    }
  };

  const onSubmitNewUser = (signUpCredentials: FormValues) => {
    trackEvent('hasSubmittedRegister');
    setRegisterFromValues(signUpCredentials);
    onSendActivationCode(signUpCredentials);
  };

  const onSubmitActivationCodeUser = (signUpCredentials: FormValues) => {
    const [firstName, lastName] = splitOnFirstSpace(signUpCredentials.fullName);

    ManagerProvider.registerUser({firstName, lastName, ...signUpCredentials})
      .then(userData => {
        if (userData) {
          setActivationCodeError('');
          if (onSuccess) {
            onSuccess(userData);
          }
        }
      })
      .catch(error => {
        trackEvent('formErrorMessageView', {
          formName: FormNamesEnum.OTP_REGISTRATION,
          errorType: error.message,
        });
        setActivationCodeError('activation_code_entry_is_wrong_please_try_again');
      });
  };

  const onCancelActivationProcess = () => {
    setActivationModeProps(null);
  };

  const backgroundColor = withTransparentBG ? 'transparent' : _backgroundColor;

  return (
    <>
      {activationModeProps && activationToken ? (
        <LayoutWithHeader
          onClick={onBackButtonClick}
          titleKey="we_sent_you_activation_code_to_your_phone"
          showBackArrow={false}
        >
          <BodyWrapper id="signup_body_wrapper_activation" pageView={pageView} backgroundColor={backgroundColor}>
            <LoginBody>
              <PhoneVerificationSection
                onSubmit={onSubmitActivationCodeUser}
                onCancel={onCancelActivationProcess}
                authenticationToken={activationToken}
                onSendActivationCode={onSendActivationCode}
                activationCodeError={activationCodeError}
                sendActivationCodeError={sendActivationCodeError}
                {...activationModeProps}
              />
            </LoginBody>
          </BodyWrapper>
        </LayoutWithHeader>
      ) : (
        <BodyWrapper
          id="register_tab_controls"
          aria-labelledby="register_tab_button"
          role="tabpanel"
          pageView={pageView}
          backgroundColor={backgroundColor}
        >
          <LoginBody>
            <SignUpForm
              onSubmit={onSubmitNewUser}
              initialValues={registerFromValues}
              sendActivationCodeError={sendActivationCodeError}
              existingEmailAction={email => openLoginScreen?.({email})}
              borderedInputs={!!withTransparentBG}
            />
            {!isFB && !disableExternalProvidersRegistration && (
              <FacebookSection
                {...{
                  t,
                  onClick: openFacebook,
                  backgroundColor,
                  btnText: t('quick_register_with_facebook'),
                  btnAriaLabel: t('quick_register_with_facebook_aria_label'),
                }}
              />
            )}
          </LoginBody>
        </BodyWrapper>
      )}
    </>
  );
};

export default SignUp;
